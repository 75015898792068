import '../../css/common/_block-services.scss';
import '../../css/common/_item-card-testimonial.scss';
import '../../css/homepage/blocks/_block-heroZone.scss';
import '../../css/homepage/blocks/_block-reassurances.scss';
import '../../css/homepage/blocks/_block-selections.scss';
import '../../css/homepage/blocks/_block-slider-publicitaire.scss';
import '../../css/homepage/blocks/_block-algolia.scss';





import '../../css/common/_block-stocks.scss';
import '../../css/common/_block-experience.scss';
import '../../css/common/_block-seo-text.scss';
import '../../css/common/_block-glide-slider.scss';
import '../../css/vehicle/_item.scss';
import '../../css/common/_block-avis-clients.scss';


// import Swiper
import { Swiper, Navigation, Pagination } from "swiper";
// Install modules
Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css';
import '../../css/common/_block-swiper.scss';

import btnMoreInItem from "../components/_btns-item-testimonial";

window.addEventListener('DOMContentLoaded', () => {

    if (document.getElementById('swiper-avis-clients')) {
        try {
            /* SWIPER */
            const swiperAvisClients = new Swiper('#swiper-avis-clients', {
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next-avis',
                    prevEl: '.swiper-button-prev-avis',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    576: {
                        slidesPerView: 1.1,
                    },
                    768: {
                        slidesPerView: 1.1,
                    },
                    950: {
                        slidesPerView: 2.1,
                    },
                    1300: {
                        slidesPerView: 3.1
                    },
                }
            });
        } catch (error) {
            console.log('Il manque le block avec l\'id "#swiper-avis-clients" pour afficher le slider correctement')
        }
    }

    // Affichage des btns "Afficher Plus/Moins" pour les "avis clients"
    btnMoreInItem();

});