//BOUTON "AFFICHER PLUS/MOINS"
import btnMoreChangeText from "./_btns-showmore";

export default function btnMoreInItem() {
    // BOUTON "AFFICHER PLUS/MOINS" - items "Avis clients"
    const tabTestimonial = document.querySelectorAll('.item-card__testimonial');
    if ( tabTestimonial ) {
        tabTestimonial.forEach( item => {
            const btnMore = item.querySelector('.btn-more');
            const tabResponseTxt = item.querySelectorAll('.item-card__testimonial--text')
            if (btnMore) {
                btnMore.addEventListener('click', (e) => {
                    console.log('CLICK SUR MORE')
                    btnMore.classList.toggle('active');
                    const BtnMoreText = btnMore.innerText;
                    btnMoreChangeText(BtnMoreText, btnMore);
                    tabResponseTxt[0].classList.toggle('d-none');
                    tabResponseTxt[1].classList.toggle('d-none');
                    e.preventDefault();
                });
            }
        }, this);
    }
}